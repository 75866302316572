import React from 'react'

import FlowerIcon from '../../assets/icons/produce/flower_icon.svg'
import AvocadoIcon from '../../assets/icons/produce/avocado_icon.svg'
import BerriesIcon from '../../assets/icons/produce/berries_icon.svg'
import BrocoliIcon from '../../assets/icons/produce/brocoli_icon.svg'
import MelonIcon from '../../assets/icons/produce/melon_icon.svg'
import PotatoIcon from '../../assets/icons/produce/potato_icon.svg'
import SaladIcon from '../../assets/icons/produce/salad_icon.svg'
import PeasIcon from '../../assets/icons/produce/peas_icon.svg'
import { ConfiguratorEnums } from './ConfiguratorLogic'

export const stepContent = [
    {
        title: "Configure your Product",
        subheader: "in five simple steps"
    },
    {
        title: "Do you need to store or process your crops on-farm?",
        subheader: "Our on-farm solutions can offer a broad range of alternatives for your post-harvest operations. Do only need to lower and mantain cold temperatures or do you also need to process and pack your fresh produce? If you would like to use an existing facility you can choose 'No'."
    },
    {
        title: "Would you like to place your unit outdoors?",
        subheader: "Our packhouses are roofed and weather resistant all-in-one solutions that can be placed outdoors."
    },
    {
        title: "What kind of produce are you handling?",
        subheader: "You can also choose similar produce"
    },
    {
        title: "What’s your daily throughput?",
        subheader: "Please give us a rough estimation"
    },
    {
        title: "One Last Thing",
        subheader: "We would like to know you"
    },
    {
        title: "Results",
        subheader: ""
    },
]

export const sliderMarks = [
    ... ConfiguratorEnums.amountOfThroughput
  ];

export const produceTypes = [
    {
        title: 'Flowers',
        value: ConfiguratorEnums.typeOfProduce.flowers,
        icon: <FlowerIcon/>,
    },
    {
        title: 'Tropical Fruits',
        value: ConfiguratorEnums.typeOfProduce.tropical,
        icon: <AvocadoIcon/>,
    },
    {
        title: 'Berries or Grapes',
        value: ConfiguratorEnums.typeOfProduce.berries,
        icon: <BerriesIcon/>,
    },
    {
        title: 'Drupes, Melons or Citrus',
        value: ConfiguratorEnums.typeOfProduce.melon,
        icon: <MelonIcon/>,
    },
    {
        title: 'Fine Peas or Beans',
        value: ConfiguratorEnums.typeOfProduce.beans,
        icon: <PeasIcon/>,
    },
    {
        title: 'Leafy Greens',
        value: ConfiguratorEnums.typeOfProduce.leafyGreens,
        icon: <SaladIcon/>,
    },
    {
        title: 'Roots',
        value: ConfiguratorEnums.typeOfProduce.roots,
        icon: <PotatoIcon/>,
    },
    {
        title: 'Cruciferous',
        value: ConfiguratorEnums.typeOfProduce.cruciferous,
        icon: <BrocoliIcon/>,
    },
]