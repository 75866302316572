import React, {useState} from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Grid, makeStyles } from '@material-ui/core';

import PhoneField from '../shared/PhoneField';

import { useRecoilState, useRecoilValue } from "recoil";
import { configuratorQuestionState, configuratorResultState, leadRecordState } from "../../store";


const useStyles = makeStyles((theme) => ({
    form: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
          },
    },
    formControl: {
        width: "100%"
    },
    input: {
        '& > div': {
            backgroundColor: theme.palette.action.hover
          },
    }
  }));


const ContactForm = ({ handleChange }) =>  {
   
    const classes = useStyles()
    const [state, setState] = useState({
        name: "",
        company: "",
        position: ""
    })


  const [ questionState, setQuestionState ] = useRecoilState(configuratorQuestionState);

    const handleSubmit = (event) => {
        event.preventDefault();
        handleChange(state)
    }
    const onPhoneFieldChange = (numberWithoutPlus) => {
        let phoneNumber
        if (numberWithoutPlus.length > 5) phoneNumber = `+${numberWithoutPlus}`
        setState({...state, phoneNumber})
    }
    return (
        <form className={classes.form} autoComplete="on" onSubmit={handleSubmit}>
            {/* <FormControl  variant="filled" className={classes.input}>
                <InputLabel id="demo-simple-select-label">Position in the supply-chain</InputLabel>
                <Select
                className={classes.input}
                    required
                    labelId="position-select"
                    id="demo-simple-select"
                    // value={age}
                    onChange={event => setState({...state, position: event.target.value})}
                >
                    <MenuItem value={"Producer"}>Producer</MenuItem>
                    <MenuItem value={"Exporter"}>Exporter</MenuItem>
                    <MenuItem value={"Distributor"}>Distributor</MenuItem>
                    <MenuItem value={"Wholesaler"}>Wholesaler</MenuItem>

                </Select>
            </FormControl> */}
            <TextField 
                onChange={event => setState({...state, name: event.target.value})}
                className={classes.input} 
                type="text" required variant="filled" id="filled-basic" label="Name" />

                
                <TextField 
                onChange={event => setState({...state, company: event.target.value})}
                className={classes.input} 
                type="text" variant="filled" id="filled-basic" label="Company" />
                 
                 <Grid item xs={12}>
                 <PhoneField handleOnChange={number => onPhoneFieldChange(number)} required></PhoneField>
                       
        </Grid>               
               
            {/* <TextField id="filled-basic" label="Filled" variant="filled" />
            <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
            <Button variant="contained" color="primary" type="submit" disabled={!state.phoneNumber}> Show me my unit!</Button>
      </form>
    )
}

export default ContactForm
