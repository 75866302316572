import React, {useState} from 'react'
import Paper from '@material-ui/core/Paper'
import PositionIndicator from './PositionIndicator'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';
import { Link } from 'gatsby-theme-material-ui';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import HorizontalStepper from '../Stepper'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import PictureButton from '../shared/PictureButton'
import ContactForm from './ContactForm'
import Results from './Results'
import ProduceSlider from './Slider'
import ConfiguratorCard from './ConfiguratorCard'
import { useRecoilState } from "recoil"
import { configuratorQuestionState, leadRecordState } from "../../store"
import ArrowLink from '../shared/ArrowLink'
import Api from '../../api'
import SuccessDialog from "../shared/SuccessDialog"
import { stepContent, produceTypes, sliderMarks } from './content'
import { ConfiguratorEnums } from './ConfiguratorLogic'

import IconProcessed from '../../assets/icons/icon_processed.svg'
import IconUnprocessed from '../../assets/icons/icon_unprocessed.svg'
import IconIndoors from '../../assets/icons/indoor_icon.svg'
import IconOutdoors from '../../assets/icons/outdoor_icon.svg'
const useStyles = makeStyles((theme) => ({
    content: {
        width: "100%",
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        maxWidth: 370,
        fontFamily: "Josefin Sans",
        textAlign: "center",
    },
    subTitle: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: 'auto',
        justifyContent: "space-around",
        width: "100%"
    },
    backButton: {
        marginRight: "auto",
    },
    booleanQuestionCard: {
        minWidth: 100,
    },
    largeIcon: {
        width: 70,
        height: "auto",
        fill: theme.palette.primary.main,
        '& button svg path': {
            fill: theme.palette.primary.main,
            '& path': {
                fill: theme.palette.primary.main,
            }
        }
      },
  }));


const Configurator = () => {
    const [configuratorQuestionStateAtom ,setConfiguratorQuestionStateAtom] = useRecoilState(configuratorQuestionState)
    const [leadRecord ,setLeadRecord] = useRecoilState(leadRecordState)
    const theme = useTheme()
    const classes = useStyles();
    const [position, setPosition] = useState(0);
    const [questionState, setQuestionState] = useState({...configuratorQuestionStateAtom})

    const nextStep = async (keys) => {
        updateBackend({...questionState, ...keys})
        setPosition(position + 1)
    }
    const previousStep = () => {
        setPosition(position - 1)
    }
    const updateBackend = async (questionState) => {
        if (!leadRecord.id) {
            await createLead(questionState)
        } 
        else Api.updateLead({ ...questionState, ...leadRecord})
    }
    const createLead = async (questionState) =>  {
        const leadRecordId = await Api.createLead({...configuratorQuestionStateAtom, ...questionState})
        setLeadRecord({id: leadRecordId})
    }
    const calculate = async (questionState) => {
        navigate("/configurator/result")
    }
    const handleProductTypeChoice = (typeOfProduct) => {
        setConfiguratorQuestionStateAtom({...configuratorQuestionStateAtom, typeOfProduct})
        nextStep({typeOfProduct})
    }

    const handleSecondProductTypeChoice = (typeOfProduct) => {
        if (configuratorQuestionStateAtom.typeOfProduct === ConfiguratorEnums.typeOfProduct.coldroom) {
            setConfiguratorQuestionStateAtom({...configuratorQuestionStateAtom, typeOfProduct})
            nextStep({typeOfProduct})
        } else nextStep({})
    }
    const handleProduceTypeChoice = (typeOfProduce) => {
        setConfiguratorQuestionStateAtom({...configuratorQuestionStateAtom, typeOfProduce})
        nextStep({typeOfProduce})
    }
    const handleThroughputAmountChoice = (value) => {
        // const semanticValue = ConfiguratorEnums.amountOfThroughput.find(throughput => throughput.value === value).label
        setConfiguratorQuestionStateAtom({...configuratorQuestionStateAtom, amountOfThroughput: value})
        // updateBackend({
        //     ...configuratorQuestionStateAtom, 
        //     amountOfThroughput: value
        // })

    }
    const handleContactDetailsSet = (state) => {
        setConfiguratorQuestionStateAtom({...configuratorQuestionStateAtom, ...state})
        updateBackend({...configuratorQuestionStateAtom, ...state})
        calculate(state);
    }

    const [dialogIsOpen, setDialogOpen] = useState(false)

    return (
        <ConfiguratorCard>
            <SuccessDialog 
                title=""
                content="You can choose similar crops if your produce is slightly different.
                We are currently not supporting the storage of animal products.
                Please get in contact with us, so we can find an individual solution" 
                acceptText="Get in Contact"
                acceptFunction={() => {window.location.href = "mailto:info@inspirafarms.com?subject=Configurator%20Crops%20Do%20Not%20Match&body=Hey%20InspiraFarms%2C%0D%0A%0D%0AI%20am%20interested%20in%20your%20product%2C%20but%20the%20choice%20of%20crops%20does%20not%20match%20my%20needs!";}}
                isOpen={dialogIsOpen} 
                handleClose={() => setDialogOpen(false)}
            ></SuccessDialog>
            <HorizontalStepper stepPosition={position} stepContent={stepContent} />
            <CardHeader
                className={classes.header}
                title={stepContent[position + 1].title}
                subheader={stepContent[position + 1].subheader}
                classes={{subheader: classes.subTitle}}
            />
            <CardContent className={classes.content}>
            { position === 0 &&
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={6} className={classes.booleanQuestionCard}>
                            <PictureButton title="Yes, I would like additional processing space!" isSVG image={<IconProcessed></IconProcessed>} onClick={() => handleProductTypeChoice("packhouse")}></PictureButton>
                        </Grid>
                        <Grid item xs={6} className={classes.booleanQuestionCard}>
                            <PictureButton title="No, I only need to cool down and store my produce." isSVG image={<IconUnprocessed></IconUnprocessed>} onClick={() => handleProductTypeChoice("coldroom")}></PictureButton>
                        </Grid>
                    </Grid>
                  }
                { position === 1 &&
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={6} className={classes.booleanQuestionCard}>
                            <PictureButton title="No, I already have an existing structure." isSVG image={<IconIndoors></IconIndoors>} onClick={() => handleSecondProductTypeChoice("coldroom")}></PictureButton>
                        </Grid>
                        <Grid item xs={6} className={classes.booleanQuestionCard}>
                            <PictureButton title="Yes, the unit will be placed outside." isSVG image={<IconOutdoors></IconOutdoors>} onClick={() => handleSecondProductTypeChoice("packhouse")}></PictureButton>
                        </Grid>
                    </Grid>
                  }
                { position === 2 &&
                    <Grid container spacing={2}>
                        {produceTypes.map( (produce, index) => (
                            <Grid item md={3} sm={4} xs={6} key={`produce_tile_${index}`}>
                                <PictureButton onClick={() => handleProduceTypeChoice(produce.value)} title={produce.title} isSVG image={produce.icon}/>
                            </Grid>
                        ))}
                    </Grid> 
                }
                { position === 3 &&
                   <ProduceSlider marks={sliderMarks} handleChange={value => handleThroughputAmountChoice(value)}/>
                }
                { position === 4 &&
                    <ContactForm handleChange={handleContactDetailsSet}/>
                }
                { position === 5 &&
                    <Results/>
                }
            </CardContent>
            <CardActions className={classes.button}>
                { position === -1 &&
                    <Button variant="contained" color="primary" onClick={nextStep}> Start Configuration</Button>
                }
                { position > -1 &&
                    <div className={classes.backButton}>
                        <ArrowLink left text="Back" onClick={previousStep} ></ArrowLink>
                    </div>    
                }
                { (position === 2)  &&
                    <Link onClick={() => setDialogOpen(true)}> Nothing matches?</Link>
                }
                { (position > 2 && position < 4)  &&
                    <Button variant="contained" color="primary" onClick={nextStep}> Next Step</Button>
                }
            </CardActions>
        </ConfiguratorCard>
    )
}

export default Configurator
