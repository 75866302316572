import React from 'react'
import {Box, Card} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import './SVGStyles.sass'

const useStyles = makeStyles((theme) => ({
    actionArea: {
      height: "100%",
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& svg': {
          margin: 'unset'
      }
    },
  }));

const PictureButton = ({title, image, onClick, isSVG, isIcon, children}) => {
    const classes = useStyles();
    return (
        <Card  onClick={onClick} className={isSVG && classes.actionArea}>
            <CardActionArea className={classes.actionArea}>
                {isSVG ? ( image ) : ( !isIcon &&
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="140"
                            src={image}
                            title="Contemplative Reptile"
                        />
                    </Box>
                
                )}
                { isIcon && 
                    children
    
                }
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p" align="center">
                       {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card> 
    )
}

export default PictureButton
