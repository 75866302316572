import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import { defaultConfiguratorQuestionState } from "../../store"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const CustomSlider = withStyles((theme) => ({
    root: {
    //   color: '#3880ff',
      height: 2,
      padding: '15px 0',
    },
    thumb: {
      height: 22,
      width: 22,
    //   backgroundColor: '#fff',
    //   boxShadow: iOSBoxShadow,
      marginTop: -11,
      marginLeft: -11,
      '&:focus, &:hover, &$active': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
        //   boxShadow: iOSBoxShadow,
        },
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% - 3px)',
      top: -55,
      color: theme.palette.primary,
      display: "none",
      '& *': {
        width: "50px",
        height: "50px",
        // background: theme.palette.action.selected,
      },
    },
    track: {
      height: 2,
    },
    rail: {
      height: 2,
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markLabel: {
        top: "40px",
    },
    markActive: {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
}))(Slider);

export default function ProduceSlider({marks, handleChange}) {

  function valuetext(value) {
    const mark = marks.find(mark => mark.value === value)
    if (mark) {
        return `mark.label`
    }
  }

  function valueLabelFormat(value) {
    return marks.find((mark) => mark.value === value).label;
  }
  const handleChangeInside = (event, value) => {
    handleChange(value)

  }

  return (
    <Box width={1}>
      <CustomSlider
        defaultValue={defaultConfiguratorQuestionState.amountOfThroughput || 5}
        onChange={handleChangeInside}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={null}
        valueLabelDisplay="auto"
        marks={marks}
      />
    </Box>
  );
}
